import styled from 'styled-components'
import Logo from '../../img/logo-sm-light.svg'

export const StyledSection = styled.section`
  padding: 6rem 0;
  background-image: url(${Logo});
  background-repeat: no-repeat;
  background-size: 500px;
  background-position: center;
  background-color: var(--gray1);
  overflow: hidden;

  @media (min-width: 992px) {
    background-size: 600px;
    background-position: left 20% center;
  }

  @media (min-width: 1200px) {
    padding: 7rem;
    background-size: 50%;
    background-position: 30% center;
  }
`

export const StyledContent = styled.div`
  box-sizing: border-box;
  margin: 0 -30px;
  padding: 2em 3em;
  background-color: #fff;
  color: var(--green2);

  @media (min-width: 768px) {
    margin: 0;
  }

  @media (min-width: 992px) {
    width: 60%;
    margin-left: auto;
  }

  @media (min-width: 1200px) {
    width: 55%;
  }
`
