import styled from 'styled-components'
import { Link } from 'gatsby'

export const ServicesList = styled.ul`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 -15px;
  padding: 0;
  list-style: none;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`

export const Item = styled.li`
  box-sizing: border-box;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 45px;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 992px) {
    width: calc(100% / 3);
  }

  @media (min-width: 1200px) {
    width: 20%;
  }
`

export const ServicesLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  font: inherit;
  color: var(--gray2);
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
`

export const ImgWrapper = styled.div`
  overflow: hidden;
  width: 180px;
  height: 180px;
  padding: 7px;
  border-radius: 50%;
  background-color: var(--green1);
`

export const ImgInner = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
  border: 10px solid #ffffff;
  border-radius: 50%;
`

export const Img = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in;

  ${ServicesLink}:hover & {
    transform: scale(1.1);
  }
`

export const Text = styled.p`
  margin: 0;
  padding: 2em 0;
  text-align: center;
  font-size: 0.75rem;
  transition: color 0.3s ease-in;

  ${ServicesLink}:hover & {
    color: var(--green1);
  }
`
