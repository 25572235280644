import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import Img from 'gatsby-image'
import Container from '../shared/Container'
import Section from '../shared/Section'
import SectionTitle from '../shared/SectionTitle'
import { FilmStrip, StripItem } from './styles'

const Certificates = ({ certificates: { heading, certificatesList } }) => {
  const [selected, setSelected] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const views = certificatesList.map(
    ({ caption, source: { childImageSharp } }) => ({
      source: childImageSharp.fluid.src,
      caption,
    })
  )

  return (
    <Section>
      <Container>
        <SectionTitle>{heading}</SectionTitle>
        <FilmStrip>
          {certificatesList.map(({ caption, source }, i) => (
            <StripItem
              key={caption}
              onClick={() => {
                setSelected(i)
                setIsOpen(true)
              }}
            >
              <Img fluid={source.childImageSharp.fluid} />
            </StripItem>
          ))}
        </FilmStrip>
        <ModalGateway>
          {isOpen && (
            <Modal onClose={() => setIsOpen(false)}>
              <Carousel currentIndex={selected} views={views} />
            </Modal>
          )}
        </ModalGateway>
      </Container>
    </Section>
  )
}

export default Certificates
