import React from 'react'
import Container from '../shared/Container'
import { HTMLContent } from '../Content'
import { StyledSection, StyledContent } from './styles'
import FadeInSection from '../FadeInSection/FadeInSection'

const About = ({ about }) => (
  <StyledSection>
    <Container>
      <StyledContent translate="left" as={FadeInSection}>
        <HTMLContent content={about} />
      </StyledContent>
    </Container>
  </StyledSection>
)

export default About
