import React from 'react'

import Layout from '../components/Layout'
import useIndexPageQuery from '../hooks/useIndexPageQuery'
import Services from '../components/Services/Services'
import About from '../components/About'
import Clients from '../components/Clients'
import Advantages from '../components/Advantages/Advantages'
import Slider from '../components/Slider/Slider'
import NewsSection from '../components/NewsSection'
import SEO from '../components/Seo/Seo'
import Certificates from '../components/Certificates'

export const IndexPageTemplate = ({
  slider,
  services,
  about,
  advantages,
  clients,
  certificates,
}) => (
  <>
    <Slider slides={slider} />
    <Services services={services} />
    <About about={about} />
    <Advantages advantages={advantages} />
    <Clients clients={clients} />
    <Certificates certificates={certificates} />
    <NewsSection />
  </>
)

const IndexPage = () => {
  const {
    title,
    slider,
    services,
    html: about,
    advantages,
    clients,
    certificates,
  } = useIndexPageQuery()

  return (
    <Layout>
      <SEO title={title} />
      <IndexPageTemplate
        slider={slider}
        services={services}
        about={about}
        clients={clients}
        advantages={advantages}
        certificates={certificates}
      />
    </Layout>
  )
}

export default IndexPage
