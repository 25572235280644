import React from 'react'
import FadeInSection from '../FadeInSection'
import Container from '../shared/Container'
import SectionTitle from '../shared/SectionTitle'
import {
  ServicesList,
  Item,
  ServicesLink,
  ImgWrapper,
  Text,
  Img,
  ImgInner,
} from './styles'
import Section from '../shared/Section'

const Services = ({ services: { heading, servicesList } }) => (
  <Section>
    <Container>
      <SectionTitle>{heading}</SectionTitle>
      <ServicesList>
        {servicesList.map(
          ({
            link: {
              fields: { slug },
              frontmatter: { title },
            },
            image,
          }) => (
            <FadeInSection as={Item} key={encodeURIComponent(slug)}>
              <ServicesLink to={slug}>
                <ImgWrapper>
                  <ImgInner>
                    <Img
                      src={
                        image.childImageSharp
                          ? image.childImageSharp.fixed.src
                          : image
                      }
                      alt=""
                    />
                  </ImgInner>
                </ImgWrapper>
                <Text>{title}</Text>
              </ServicesLink>
            </FadeInSection>
          )
        )}
      </ServicesList>
    </Container>
  </Section>
)

export default Services
