import styled from 'styled-components'
import Img from 'gatsby-image'

export const SliderWrapper = styled.div`
  box-sizing: border-box;
  height: calc(100vh - 85px);
  min-height: -webkit-fill-available;
`

export const StyledSlider = styled.ul`
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: transform ease-in 0.5s;
  overflow: hidden;
`

export const Slide = styled.li`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ current }) => (current ? 1 : 0)};
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  transition: ease-in-out 0.7s;
  transform: ${({ next }) => (next ? 'translateX(100%)' : 'none')};
`

export const SlideImage = styled(Img)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
`

export const SlideContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const SlideInfo = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  height: 100%;
  color: #fff;
`

export const SlideText = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
`
