import styled from 'styled-components'

export const FilmStrip = styled.div`
  box-sizing: border-box;
  display: flex;
  margin: 0 -2px;
  padding: 0;
  list-style: none;
  overflow-x: auto;
  scrollbar-width: auto;
  scrollbar-color: var(--green1) var(--gray1);

  &::-webkit-scrollbar {
    height: 15px;
    background-color: var(--gray1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--green1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px var(--gray2);
    border-radius: 10px;
  }
`

export const StripItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  padding: 2px;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 992px) {
    width: 25%;
  }
`
