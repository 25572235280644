import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 2em;
`

export const NewsTitle = styled.h3`
  margin: 0;
  text-align: left;
`

export const NewsLink = styled(Link)`
  color: var(--green1);
  text-decoration: none;
`

export const NewsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -8px;
  padding: 0;
  list-style: none;
`

export const NewsItem = styled.li`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  margin-bottom: 2.5em;
  padding: 0 8px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    width: 33%;
    max-width: 400px;
  }
`

export const NewsItemLink = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: 992px) {
    flex-direction: column;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 0.5em;

  @media (min-width: 768px) {
    width: 40%;
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    width: 100%;
    margin-bottom: 0.5em;
  }
`

export const ItemImage = styled(Img)`
  width: 100%;
`

export const ItemBody = styled.div`
  padding: 0.5em 0;

  @media (min-width: 768px) {
    padding: 0 1em;
  }
`

export const ItemTitle = styled.h4`
  margin: 0 0 0.5em;
  text-transform: capitalize;
  font-size: 1.2rem;
`

export const ItemFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.5em 0;
  font-weight: 700;
  color: var(--gray4);
`
