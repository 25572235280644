import React, { useState, useRef, useEffect } from 'react'
import {
  StyledSlider,
  Slide,
  SlideImage,
  SlideContent,
  SlideInfo,
  SlideText,
  SliderWrapper,
} from './styles'
import Container from '../shared/Container'

const ANIMATED_TIME = 7000

const Slider = ({ slides }) => {
  const [currentSlide, setCurrent] = useState(0)
  const [nextSlide, setNext] = useState(currentSlide + 1)
  const frame = useRef(null)
  const timer = useRef(null)

  const move = () => {
    setCurrent(nextSlide)
    const next = nextSlide >= slides.length - 1 ? 0 : nextSlide + 1
    setNext(next)
  }

  useEffect(() => {
    const id = setInterval(() => {
      move()
    }, ANIMATED_TIME)
    timer.current = id
    return () => {
      clearInterval(timer.current)
    }
  })

  return (
    <SliderWrapper>
      <StyledSlider ref={frame}>
        {slides.map(
          (
            {
              heading,
              description,
              image: {
                childImageSharp: { fluid },
              },
            },
            i
          ) => (
            <Slide
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              current={currentSlide === i}
              next={nextSlide === i}
            >
              <SlideImage fluid={fluid} />
              <SlideContent>
                <Container>
                  <SlideInfo>
                    {heading && <h2>{heading}</h2>}
                    {description && <SlideText>{description}</SlideText>}
                  </SlideInfo>
                </Container>
              </SlideContent>
            </Slide>
          )
        )}
      </StyledSlider>
    </SliderWrapper>
  )
}

export default Slider
