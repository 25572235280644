import styled from 'styled-components'

export const ClientsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ListItem = styled.li`
  box-sizing: border-box;
  display: flex;
  width: 50%;
  margin-bottom: 20px;
  padding: 0 10px;

  @media (min-width: 576px) {
    width: calc(100% / 3);
  }

  @media (min-width: 992px) {
    width: calc(100% / 4);
  }

  @media (min-width: 1200px) {
    width: calc(100% / 5);
  }
`

export const Link = styled.a`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  filter: grayscale();
  transition: filter ease-in 0.3s, background-color ease-in 0.3s;

  &:hover {
    filter: none;
    background-color: #fff;
  }

  @media (min-width: 992px) {
    padding: 10px 15px;
  }
`

export const Logo = styled.img`
  width: 100%;
  max-width: 150px;
  max-height: 75px;
  height: auto;
  object-fit: contain;
`
