import React from 'react'
import useNewsQuery from '../../hooks/useNewsQuery'
import Container from '../shared/Container'
import {
  Head,
  NewsTitle,
  NewsLink,
  NewsList,
  NewsItem,
  NewsItemLink,
  ItemTitle,
  ImageWrapper,
  ItemImage,
  ItemBody,
  ItemFooter,
} from './styles'
import Section from '../shared/Section'

const NewsSection = () => {
  const news = useNewsQuery()
  const newsToShow = news.slice(0, 3)

  const allNewsPath = '/news'

  return (
    <Container>
      <Section>
        <Head>
          <NewsTitle>Новини</NewsTitle>
          <NewsLink to={allNewsPath}>Усі новини:</NewsLink>
        </Head>
        <NewsList>
          {newsToShow.map(
            ({
              excerpt,
              id,
              fields: { slug },
              frontmatter: { title, date, featuredimage },
            }) => (
              <NewsItem key={id}>
                <NewsItemLink to={slug}>
                  {featuredimage && (
                    <ImageWrapper>
                      <ItemImage fluid={featuredimage.childImageSharp.fluid} />
                    </ImageWrapper>
                  )}
                  <ItemBody>
                    <ItemTitle>{title}</ItemTitle>
                    <p>{excerpt}</p>
                    <ItemFooter>
                      {date}
                      <NewsLink to={slug}>Читати далі...</NewsLink>
                    </ItemFooter>
                  </ItemBody>
                </NewsItemLink>
              </NewsItem>
            )
          )}
        </NewsList>
      </Section>
    </Container>
  )
}

export default NewsSection
