import styled from 'styled-components'

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Item = styled.li`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5em;
  padding-left: 45px;
  padding-right: 45px;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 992px) {
    width: calc(100% / 3);
  }
`

export const ItemTitle = styled.h4`
  text-align: center;
`

export const Icon = styled.img`
  width: 55px;
  height: 55px;
`

export const Descrtiption = styled.p`
  max-width: 350px;
  text-align: center;
`
