import React from 'react'
import Section from '../shared/Section'
import Container from '../shared/Container'
import SectionTitle from '../shared/SectionTitle'
import { List, Item, Icon, Descrtiption, ItemTitle } from './styles'
import FadeInSection from '../FadeInSection'

const Advantages = ({ advantages: { heading, advantagesList } }) => (
  <Section>
    <Container>
      <SectionTitle>{heading}</SectionTitle>
      <List>
        {advantagesList.map(({ title, description, icon: { publicURL } }) => (
          <FadeInSection as={Item} key={encodeURIComponent(title)}>
            <Icon src={publicURL} />
            <ItemTitle>{title}</ItemTitle>
            <Descrtiption>{description}</Descrtiption>
          </FadeInSection>
        ))}
      </List>
    </Container>
  </Section>
)

export default Advantages
