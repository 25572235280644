import React from 'react'
import Section from '../shared/Section'
import Container from '../shared/Container'
import SectionTitle from '../shared/SectionTitle'
import { ClientsList, Logo, Link, ListItem } from './styles'

const Clients = ({ clients: { heading, clientsList } }) => (
  <Section dark>
    <Container>
      <SectionTitle>{heading}</SectionTitle>
      <ClientsList>
        {clientsList.map(({ link, name, logo: { publicURL } }) => (
          <ListItem key={name}>
            <Link href={link}>
              <Logo src={publicURL} alt={name} />
            </Link>
          </ListItem>
        ))}
      </ClientsList>
    </Container>
  </Section>
)

export default Clients
